import { ArrowLeft } from 'lucide-react';

const Disclosures = () => {
  return (
    <div className="min-h-screen bg-[#090D1F] text-white p-8">
      <div className="max-w-4xl mx-auto space-y-8">
        <h1 className="text-4xl font-bold mb-8">Disclosures</h1>

        {/* Back Button */}
        <a href="/" className="inline-flex items-center gap-2 text-blue-100 hover:text-white mb-12 group">
          <ArrowLeft className="w-4 h-4 group-hover:-translate-x-1 transition-transform"/>
          Back to Home
        </a>

        {/* Privacy Policy */}
        <section className="mb-16">
          <h1 className="text-3xl font-bold text-white mb-8">Privacy Policy</h1>
          
          <div className="space-y-8 text-blue-100">
            <div>
              <h2 className="text-xl font-semibold text-white mb-4">1. Introduction</h2>
              <p>
                At Market Bots, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, 
                and safeguard your information when you use our AI-powered marketing automation services.
              </p>
            </div>

            <div>
              <h2 className="text-xl font-semibold text-white mb-4">2. Information We Collect</h2>
              <p className="mb-4">We collect information that you provide directly to us, including:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Account information (name, email, business details)</li>
                <li>Marketing campaign data and preferences</li>
                <li>Communication history and preferences</li>
                <li>Payment information</li>
                <li>Usage data and analytics</li>
              </ul>
            </div>

            <div>
              <h2 className="text-xl font-semibold text-white mb-4">3. How We Use Your Information</h2>
              <p className="mb-4">We use the collected information to:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Provide and improve our AI marketing services</li>
                <li>Personalize your experience</li>
                <li>Process payments and transactions</li>
                <li>Send important service updates</li>
                <li>Analyze and optimize our services</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Terms of Service */}
        <section>
          <h1 className="text-3xl font-bold text-white mb-8">Terms of Service</h1>
          
          <div className="space-y-8 text-blue-100">
            <div>
              <h2 className="text-xl font-semibold text-white mb-4">1. Agreement to Terms</h2>
              <p>
                By accessing or using Market Bots' services, you agree to be bound by these Terms of Service. 
                If you disagree with any part of these terms, you may not access our services.
              </p>
            </div>

            <div>
              <h2 className="text-xl font-semibold text-white mb-4">2. Description of Services</h2>
              <p className="mb-4">Market Bots provides AI-powered marketing automation services, including:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Marketing campaign automation</li>
                <li>Content generation and optimization</li>
                <li>Analytics and reporting</li>
                <li>Customer engagement tools</li>
                <li>Integration with marketing platforms</li>
              </ul>
            </div>

            <div>
              <h2 className="text-xl font-semibold text-white mb-4">3. User Responsibilities</h2>
              <p className="mb-4">You agree to:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Provide accurate account information</li>
                <li>Maintain the security of your account</li>
                <li>Comply with all applicable laws and regulations</li>
                <li>Use our services in a responsible manner</li>
                <li>Respect intellectual property rights</li>
              </ul>
            </div>
          </div>
        </section>

        <div className="mt-12 pt-8 border-t border-white/10 text-sm text-blue-100">
          Last Updated: January 1, 2025
        </div>
      </div>
    </div>
  );
};

export default Disclosures;

import React from 'react';
import { Star } from 'lucide-react';

interface Testimonial {
  name: string;
  company: string;
  image: string;
  quote: string;
}

const testimonials: Testimonial[] = [
  {
    name: 'Al Walsh',
    company: 'AHS Junk Removal',
    image: 'https://storage.googleapis.com/msgsndr/ePtmNgAt7JNCjT9N7Bn1/media/660459c326422b93ff19e4e0.png',
    quote: 'The automated review system alone has transformed our reputation. We went from 50 reviews to over 90 in just three months.'
  },
  {
    name: 'Sarah Johnson',
    company: 'Elite Real Estate',
    image: 'https://storage.googleapis.com/msgsndr/d50sJaQC2xmO4yPiCCC8/media/675c84f2bd6529ef077afe02.png',
    quote: 'I was skeptical about AI at first, but this system has proven invaluable. Our online presence has skyrocketed and leads come in 24/7.'
  },
  {
    name: 'Mike D.',
    company: 'Davis HVAC Solutions',
    image: 'https://storage.googleapis.com/msgsndr/d50sJaQC2xmO4yPiCCC8/media/675c84f25bf425e4fe643688.png',
    quote: 'The AI booking has completely transformed our business. We\'ve seen a huge boost in leads and our booking system runs like clockwork.'
  }
];

const Testimonials: React.FC = () => {
  return (
    <section className="py-24 bg-[#090D1F] relative overflow-hidden" id="testimonials">
      {/* Enhanced Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-[#090D1F] via-[#090D1F] to-[#090D1F] opacity-90"/>
        <div className="absolute top-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-indigo-500 to-transparent"/>
        <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-purple-500 to-transparent"/>
      </div>

      <div className="container mx-auto px-4 relative">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
            Success Stories
          </h2>
          <p className="text-xl text-gray-400">
            See how businesses are transforming with our AI-powered solutions
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="bg-[#0A0B2C] rounded-2xl p-8 backdrop-blur-sm border border-white/10 hover:border-white/20 transition-all duration-300"
            >
              <div className="flex items-center mb-6">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="w-16 h-16 rounded-full object-cover mr-4"
                />
                <div>
                  <h3 className="font-bold text-white text-lg">{testimonial.name}</h3>
                  <p className="text-gray-400 text-sm">{testimonial.company}</p>
                </div>
              </div>

              <div className="flex mb-4">
                {[...Array(5)].map((_, i) => (
                  <Star
                    key={i}
                    className="w-5 h-5 text-yellow-400 fill-yellow-400"
                  />
                ))}
              </div>

              <blockquote className="text-gray-300">
                "{testimonial.quote}"
              </blockquote>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

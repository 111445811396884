import React from 'react';
import FormField from '../FormField';

interface GrowthGoalsProps {
  formData: {
    targetLeads: string;
    revenueGoal: string;
    startDate: string;
  };
  onChange: (name: string, value: string) => void;
  errors: {
    targetLeads?: string;
    revenueGoal?: string;
    startDate?: string;
  };
}

const GrowthGoals: React.FC<GrowthGoalsProps> = ({ formData, onChange, errors }) => {
  const leadRanges = [
    { value: '25-50', label: '25-50 leads per month' },
    { value: '51-100', label: '51-100 leads per month' },
    { value: '101-200', label: '101-200 leads per month' },
    { value: '201-500', label: '201-500 leads per month' },
    { value: '500+', label: '500+ leads per month' }
  ];

  const revenueRanges = [
    { value: '100k', label: '$100k per month' },
    { value: '250k', label: '$250k per month' },
    { value: '500k', label: '$500k per month' },
    { value: '1m', label: '$1M per month' },
    { value: '1m+', label: '$1M+ per month' }
  ];

  const startDateRanges = [
    { value: 'immediately', label: 'As soon as possible' },
    { value: '2-weeks', label: 'Within 2 weeks' },
    { value: '1-month', label: 'Within 1 month' },
    { value: '2-3-months', label: 'In 2-3 months' },
    { value: '3-months+', label: 'More than 3 months' }
  ];

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-white mb-8">What are your growth goals?</h2>
      
      <FormField
        label="How many leads would you like to generate per month?"
        name="targetLeads"
        type="select"
        value={formData.targetLeads}
        onChange={onChange}
        options={leadRanges}
        required
        error={errors.targetLeads}
      />
      
      <div className="text-sm text-gray-400 -mt-4 mb-6">
        We'll help you create a realistic plan to achieve this goal
      </div>
      
      <FormField
        label="What's your monthly revenue goal?"
        name="revenueGoal"
        type="select"
        value={formData.revenueGoal}
        onChange={onChange}
        options={revenueRanges}
        required
        error={errors.revenueGoal}
      />

      <FormField
        label="When would you like to start?"
        name="startDate"
        type="select"
        value={formData.startDate}
        onChange={onChange}
        options={startDateRanges}
        required
        error={errors.startDate}
      />
    </div>
  );
};

export default GrowthGoals;

export const GA_TRACKING_ID = 'G-RBJZ0G69ZZ';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  window.gtag?.('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: {
  action: string;
  category: string;
  label: string;
  value?: number;
}) => {
  window.gtag?.('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

// Track form submissions
export const trackFormSubmission = (formName: string, success: boolean) => {
  event({
    action: success ? 'form_submit_success' : 'form_submit_failure',
    category: 'Forms',
    label: formName,
  });
};

// Track button clicks
export const trackButtonClick = (buttonName: string) => {
  event({
    action: 'button_click',
    category: 'Engagement',
    label: buttonName,
  });
};

// Track section views
export const trackSectionView = (sectionName: string) => {
  event({
    action: 'section_view',
    category: 'Navigation',
    label: sectionName,
  });
};

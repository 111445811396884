import React from 'react';

interface Point {
  icon: string;
  title: string;
  description: string;
  gradient?: string;
}

const PainPoints: React.FC = () => {
  const struggles: Point[] = [
    {
      icon: '🕒',
      gradient: 'from-red-500 via-orange-500 to-yellow-500',
      title: 'Time-Consuming Manual Work',
      description: 'Spending countless hours managing leads, following up, and handling customer communications manually.'
    },
    {
      icon: '💰',
      gradient: 'from-yellow-500 via-green-500 to-emerald-500',
      title: 'Lost Revenue',
      description: 'Missing out on potential customers due to slow response times and inconsistent follow-up.'
    },
    {
      icon: '📊',
      gradient: 'from-emerald-500 via-teal-500 to-cyan-500',
      title: 'Poor Lead Management',
      description: 'Struggling to keep track of leads and maintain organized customer data.'
    },
    {
      icon: '🌐',
      gradient: 'from-cyan-500 via-blue-500 to-indigo-500',
      title: 'Limited Online Presence',
      description: 'Finding it difficult to maintain a strong online presence and engage with customers effectively.'
    }
  ];

  const solutions: Point[] = [
    {
      icon: '🤖',
      gradient: 'from-purple-500 via-pink-500 to-red-500',
      title: 'AI-Powered Automation',
      description: 'Let our AI handle lead capture, follow-ups, and customer communications 24/7.'
    },
    {
      icon: '⚡',
      gradient: 'from-red-500 via-orange-500 to-yellow-500',
      title: 'Instant Response',
      description: 'Never miss a lead with automated instant responses and follow-ups.'
    },
    {
      icon: '📱',
      gradient: 'from-yellow-500 via-green-500 to-emerald-500',
      title: 'Multi-Channel Management',
      description: 'Efficiently manage leads across all channels from a single dashboard.'
    },
    {
      icon: '⭐',
      gradient: 'from-blue-500 via-indigo-500 to-purple-500',
      title: 'Reputation Management',
      description: 'Automatically collect and manage reviews, building a strong online presence that attracts more customers.'
    }
  ];

  return (
    <section className="py-24 bg-[#090D1F] relative overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(#394BFF_1px,transparent_1px)] [background-size:16px_16px] opacity-[0.15]" />
        <div className="absolute inset-0 bg-gradient-to-b from-[#090D1F] via-transparent to-[#090D1F]" />
      </div>

      <div className="container mx-auto px-4 relative">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-6">
            Transform Your Business Growth
          </h2>
          <p className="text-xl text-gray-300">
            Stop struggling with outdated marketing methods. Experience the power of AI automation.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
          <div>
            <h3 className="text-2xl font-semibold mb-8 text-center">Common Struggles</h3>
            <div className="space-y-6">
              {struggles.map((point, index) => (
                <div key={index} className="flex items-start gap-4 bg-white/5 p-6 rounded-xl backdrop-blur-sm">
                  <div className={`w-10 h-10 rounded-lg bg-gradient-to-r ${point.gradient} flex items-center justify-center`}>
                    <span className="text-xl">{point.icon}</span>
                  </div>
                  <div>
                    <h4 className="font-semibold mb-2">{point.title}</h4>
                    <p className="text-gray-300">{point.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h3 className="text-2xl font-semibold mb-8 text-center">Our Solutions</h3>
            <div className="space-y-6">
              {solutions.map((point, index) => (
                <div key={index} className="flex items-start gap-4 bg-white/5 p-6 rounded-xl backdrop-blur-sm">
                  <div className={`w-10 h-10 rounded-lg bg-gradient-to-r ${point.gradient} flex items-center justify-center`}>
                    <span className="text-xl">{point.icon}</span>
                  </div>
                  <div>
                    <h4 className="font-semibold mb-2">{point.title}</h4>
                    <p className="text-gray-300">{point.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PainPoints;

import React from 'react';
import FormField from '../FormField';

interface ContactInfoProps {
  formData: {
    name: string;
    email: string;
    phone: string;
  };
  onChange: (name: string, value: string) => void;
  errors: {
    name?: string;
    email?: string;
    phone?: string;
  };
}

const ContactInfo: React.FC<ContactInfoProps> = ({ formData, onChange, errors }) => {
  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-white mb-8">How can we contact you?</h2>
      
      <FormField
        label="What's your name?"
        name="name"
        type="text"
        value={formData.name}
        onChange={onChange}
        placeholder="Enter your full name"
        required
        error={errors.name}
      />
      
      <FormField
        label="What's your email?"
        name="email"
        type="email"
        value={formData.email}
        onChange={onChange}
        placeholder="Enter your email address"
        required
        error={errors.email}
      />

      <FormField
        label="What's your phone number?"
        name="phone"
        type="tel"
        value={formData.phone}
        onChange={onChange}
        placeholder="Enter your phone number"
        required
        error={errors.phone}
      />
    </div>
  );
};

export default ContactInfo;

import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Hero from './components/home/Hero';
import Features from './components/home/Features';
import { Timeline } from './components/home/Timeline';
import Testimonials from './components/home/Testimonials';
import Pricing from './components/home/Pricing';
import FAQ from './components/home/FAQ';
import PainPoints from './components/home/PainPoints';
import ApplicationForm from './components/forms/ApplicationForm';
import CookieConsent from './components/common/CookieConsent';
import SchemaMarkup from './components/SEO/SchemaMarkup';
import Disclosures from './components/pages/Disclosures';
import LoadingScreen from './components/common/LoadingScreen';

type SectionType = 'home' | 'features' | 'pricing' | 'testimonials';

interface SeoMetadata {
  title: string;
  description: string;
  keywords: string;
}

// SEO metadata for each section
const seoMetadata: Record<SectionType, SeoMetadata> = {
  home: {
    title: "Market Bots | AI-Powered Lead Generation & Business Automation",
    description: "Transform your business with AI-powered lead generation, automation, and growth solutions. Get more leads and scale your business with Market Bots.",
    keywords: "ai lead generation, business automation, marketing automation, lead generation software, ai marketing"
  },
  features: {
    title: "AI Marketing Features | Market Bots",
    description: "Discover our powerful AI marketing features: lead generation, automation, CRM integration, and more. See how Market Bots can transform your business.",
    keywords: "ai marketing features, lead generation tools, marketing automation features, crm integration"
  },
  pricing: {
    title: "Pricing Plans | Market Bots AI Marketing Solutions",
    description: "Flexible pricing plans for businesses of all sizes. Start generating more leads with our AI-powered marketing solutions.",
    keywords: "ai marketing pricing, lead generation cost, marketing automation plans"
  },
  testimonials: {
    title: "Client Success Stories | Market Bots",
    description: "See how businesses are transforming their lead generation and growth with Market Bots. Real results from real clients.",
    keywords: "ai marketing testimonials, client success stories, lead generation results"
  }
};

function App() {
  const [showForm, setShowForm] = useState(false);
  const [currentSection, setCurrentSection] = useState<SectionType>('home');
  const [showDisclosures, setShowDisclosures] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleGetStarted = () => {
    setShowForm(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScroll = () => {
    const sections = ['home', 'features', 'pricing', 'testimonials'] as const;
    const scrollPosition = window.scrollY + window.innerHeight / 2;

    for (const section of sections) {
      const element = document.getElementById(section);
      if (element && 
          scrollPosition >= element.offsetTop && 
          scrollPosition < element.offsetTop + element.offsetHeight) {
        setCurrentSection(section);
        break;
      }
    }
  };

  useEffect(() => {
    const throttledScroll = () => {
      if (!window.requestAnimationFrame) {
        setTimeout(handleScroll, 66);
        return;
      }
      window.requestAnimationFrame(handleScroll);
    };

    window.addEventListener('scroll', throttledScroll);
    return () => window.removeEventListener('scroll', throttledScroll);
  }, []);

  useEffect(() => {
    // Simulate initial loading
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Check if the current path is /disclosures
    if (window.location.pathname === '/disclosures') {
      setShowDisclosures(true);
    }
  }, []);

  // If showing disclosures, render the Disclosures component
  if (showDisclosures) {
    return <Disclosures />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="min-h-screen bg-[#090D1F] text-white flex flex-col">
      <Helmet>
        <title>{seoMetadata[currentSection].title}</title>
        <meta name="description" content={seoMetadata[currentSection].description} />
        <meta name="keywords" content={seoMetadata[currentSection].keywords} />
        
        {/* Open Graph / Social Media Meta Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={seoMetadata[currentSection].title} />
        <meta property="og:description" content={seoMetadata[currentSection].description} />
        <meta property="og:url" content={`https://marketbots.com/${currentSection === 'home' ? '' : currentSection}`} />
        <meta property="og:image" content="https://marketbots.com/og-image.jpg" />
        
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoMetadata[currentSection].title} />
        <meta name="twitter:description" content={seoMetadata[currentSection].description} />
        <meta name="twitter:image" content="https://marketbots.com/twitter-card.jpg" />
        
        {/* Canonical URL */}
        <link rel="canonical" href={`https://marketbots.com/${currentSection === 'home' ? '' : currentSection}`} />
        
        {/* Additional SEO Meta Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      {/* Add Schema Markup */}
      <SchemaMarkup />

      <Navbar onGetStarted={handleGetStarted} />
      
      <main className="flex-grow">
        {showForm ? (
          <ApplicationForm onClose={() => setShowForm(false)} />
        ) : (
          <>
            <section id="home">
              <Hero onGetStarted={handleGetStarted} />
              <PainPoints />
            </section>
            <section id="features">
              <Features />
              <Timeline />
            </section>
            <section id="pricing">
              <Pricing onGetStarted={handleGetStarted} />
            </section>
            <section id="testimonials">
              <Testimonials />
            </section>
            <FAQ />
          </>
        )}
      </main>

      <Footer />
      <CookieConsent />
    </div>
  );
}

export default App;

import React from 'react';
import { CheckCircle, ArrowRight } from 'lucide-react';

interface ThankYouProps {
  onClose: () => void;
}

const ThankYou: React.FC<ThankYouProps> = ({ onClose }) => {
  return (
    <div className="text-center">
      <div className="w-16 h-16 mx-auto mb-6 rounded-full bg-secondary/20 flex items-center justify-center">
        <CheckCircle className="w-8 h-8 text-secondary"/>
      </div>

      <h1 className="text-3xl font-bold text-white mb-4">Application Submitted!</h1>
      <p className="text-blue-100 mb-8">
        Thank you for your interest. Our team will review your application and get back to you within 24 hours.
      </p>

      <div className="max-w-md mx-auto">
        <h2 className="text-xl font-semibold text-white mb-4">What Happens Next?</h2>
        <div className="space-y-4">
          {[
            'Our team will review your application within 24 hours',
            'We\'ll prepare a custom growth strategy for your business',
            'Schedule a strategy call to discuss implementation'
          ].map((step, index) => (
            <div key={index} className="flex items-center gap-3 text-left">
              <div className="w-6 h-6 rounded-full bg-secondary/20 flex-shrink-0 flex items-center justify-center">
                <ArrowRight className="w-4 h-4 text-secondary"/>
              </div>
              <span className="text-blue-100">{step}</span>
            </div>
          ))}
        </div>
      </div>

      <button 
        onClick={onClose} 
        className="mt-8 px-6 py-3 bg-secondary hover:bg-secondary/90 text-white rounded-lg transition-colors"
      >
        Return to Homepage
      </button>
    </div>
  );
};

export default ThankYou;

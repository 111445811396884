import React, { useState } from 'react';
import { X } from 'lucide-react';
import { 
  BusinessInfo,
  CurrentPerformance,
  GrowthGoals,
  ContactInfo,
  AdditionalDetails,
  ThankYou
} from './steps';
import { ProgressBar } from './components';
import { trackFormSubmission } from '../../../lib/analytics';

interface FormData {
  businessName: string;
  industry: string;
  website: string;
  currentLeads: string;
  currentRevenue: string;
  adSpend: string;
  targetLeads: string;
  revenueGoal: string;
  startDate: string;
  name: string;
  email: string;
  phone: string;
  challenge: string;
  [key: string]: string;
}

interface FormErrors {
  businessName?: string;
  industry?: string;
  website?: string;
  currentLeads?: string;
  currentRevenue?: string;
  adSpend?: string;
  targetLeads?: string;
  revenueGoal?: string;
  startDate?: string;
  name?: string;
  email?: string;
  phone?: string;
  challenge?: string;
  [key: string]: string | undefined;
}

interface ApplicationFormProps {
  onClose: () => void;
}

const WEBHOOK_URL = import.meta.env.VITE_WEBHOOK_URL;

const steps = [
  'Business Information',
  'Current Performance',
  'Growth Goals',
  'Contact Information',
  'Additional Details'
];

const initialFormData: FormData = {
  businessName: '',
  industry: '',
  website: '',
  currentLeads: '',
  currentRevenue: '',
  adSpend: '',
  targetLeads: '',
  revenueGoal: '',
  startDate: '',
  name: '',
  email: '',
  phone: '',
  challenge: ''
};

const ApplicationForm: React.FC<ApplicationFormProps> = ({ onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [error, setError] = useState('');

  const validateStep = (step: number): boolean => {
    const newErrors: FormErrors = {};
    
    switch (step) {
      case 0: // Business Info
        if (!formData.businessName.trim()) {
          newErrors.businessName = 'Business name is required';
        }
        if (!formData.industry) {
          newErrors.industry = 'Industry is required';
        }
        break;
        
      case 1: // Current Performance
        if (!formData.currentLeads) {
          newErrors.currentLeads = 'Current leads is required';
        }
        if (!formData.currentRevenue) {
          newErrors.currentRevenue = 'Current revenue is required';
        }
        if (!formData.adSpend) {
          newErrors.adSpend = 'Ad spend is required';
        }
        break;
        
      case 2: // Growth Goals
        if (!formData.targetLeads) {
          newErrors.targetLeads = 'Target leads is required';
        }
        if (!formData.revenueGoal) {
          newErrors.revenueGoal = 'Revenue goal is required';
        }
        if (!formData.startDate) {
          newErrors.startDate = 'Start date is required';
        }
        break;
        
      case 3: // Contact Info
        if (!formData.name.trim()) {
          newErrors.name = 'Name is required';
        }
        if (!formData.email.trim()) {
          newErrors.email = 'Email is required';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
          newErrors.email = 'Please enter a valid email address';
        }
        if (!formData.phone.trim()) {
          newErrors.phone = 'Phone number is required';
        }
        break;
        
      case 4: // Additional Details
        if (!formData.challenge) {
          newErrors.challenge = 'Please select your biggest challenge';
        }
        break;
    }
    
    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (name: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    }
  };

  const handleNext = () => {
    if (validateStep(currentStep)) {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const handleSubmit = async () => {
    if (!validateStep(currentStep)) return;

    try {
      const response = await fetch(WEBHOOK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      trackFormSubmission('application', true);
      setCurrentStep(steps.length);
    } catch (error) {
      trackFormSubmission('application', false);
      setError('An error occurred while submitting the form. Please try again.');
      if (import.meta.env.DEV) {
        console.error('Error submitting form:', error);
      }
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <BusinessInfo
            formData={formData}
            onChange={handleInputChange}
            errors={formErrors}
          />
        );
      case 1:
        return (
          <CurrentPerformance
            formData={formData}
            onChange={handleInputChange}
            errors={formErrors}
          />
        );
      case 2:
        return (
          <GrowthGoals
            formData={formData}
            onChange={handleInputChange}
            errors={formErrors}
          />
        );
      case 3:
        return (
          <ContactInfo
            formData={formData}
            onChange={handleInputChange}
            errors={formErrors}
          />
        );
      case 4:
        return (
          <AdditionalDetails
            formData={formData}
            onChange={handleInputChange}
            errors={formErrors}
          />
        );
      case 5:
        return <ThankYou onClose={onClose} />;
      default:
        return null;
    }
  };

  return (
    <section className="min-h-screen bg-[#000437] py-24">
      <div className="container max-w-3xl mx-auto px-4">
        <div className="relative">
          <button onClick={onClose} className="absolute -top-4 -right-4 w-8 h-8 bg-white/10 hover:bg-white/20 rounded-full flex items-center justify-center text-white transition-colors">
            <X className="w-4 h-4"/>
          </button>

          <div className="text-center mb-12">
            <div className="inline-flex items-center justify-center gap-2 px-4 py-2 rounded-full bg-secondary/10 text-secondary text-sm font-medium backdrop-blur-sm mb-4">
              <span className="w-4 h-4">🚀</span>
              90-Day Transformation Program
            </div>
            <h1 className="text-4xl font-bold text-white mb-4">
              Build Your Own{' '}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-secondary to-blue-400">
                In-House Marketing Agency
              </span>
            </h1>
            <p className="text-xl text-blue-100">
              Let's customize your AI marketing system
            </p>
          </div>

          <div className="bg-[#0A0B2C]/80 backdrop-blur-xl rounded-2xl p-8 border border-white/5">
            {currentStep < steps.length && (
              <ProgressBar currentStep={currentStep} totalSteps={steps.length}/>
            )}
            
            <div className={`mt-8 ${currentStep === steps.length ? 'max-w-2xl mx-auto' : ''}`}>
              {renderStep()}
              
              {currentStep < steps.length && (
                <div className="flex justify-between mt-6">
                  {currentStep > 0 && (
                    <button
                      type="button"
                      onClick={handleBack}
                      className="px-6 py-2 bg-white/5 hover:bg-white/10 text-white rounded-lg transition-colors"
                    >
                      Back
                    </button>
                  )}
                  {currentStep === steps.length - 1 ? (
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="px-6 py-2 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-lg hover:opacity-90 transition-opacity ml-auto"
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={handleNext}
                      className="px-6 py-2 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-lg hover:opacity-90 transition-opacity ml-auto"
                    >
                      Next
                    </button>
                  )}
                </div>
              )}
              {error && (
                <div className="text-red-500 mt-4">{error}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApplicationForm;

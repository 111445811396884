import React from 'react';
import FormField from '../FormField';

interface AdditionalDetailsProps {
  formData: {
    challenge: string;
  };
  onChange: (name: string, value: string) => void;
  errors: {
    challenge?: string;
  };
}

const AdditionalDetails: React.FC<AdditionalDetailsProps> = ({ formData, onChange, errors }) => {
  const challenges = [
    { value: 'leads', label: 'Not enough leads' },
    { value: 'conversion', label: 'Poor lead conversion' },
    { value: 'costs', label: 'High marketing costs' },
    { value: 'roi', label: 'Low marketing ROI' },
    { value: 'competition', label: 'Intense competition' },
    { value: 'other', label: 'Other' }
  ];

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-white mb-8">One last question...</h2>
      
      <FormField
        label="What's your biggest marketing challenge right now?"
        name="challenge"
        type="select"
        value={formData.challenge}
        onChange={onChange}
        options={challenges}
        required
        error={errors.challenge}
      />
    </div>
  );
};

export default AdditionalDetails;
